@font-face {
  font-family: SpaceGrotesk;
  src: url("./font/SpaceGrotesk-Medium.ttf");
  font-weight: normal;
  font-style: medium;
}

@font-face {
  font-family: RobotoMono;
  src: url("./font/RobotoMono-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

.space {
  font-family: SpaceGrotesk;
}

.roboto {
  font-family: RobotoMono;
}

.medium {
  font-weight: 500;
}

.capitalize {
  text-transform: capitalize;
}
body {
  background-color: #141414;
}
#root {
  height: 100%;
}
.App {
  text-align: center;
  height: 100%;
  background: rgb(20, 20, 20);
}
.ant-page-header .ant-page-header-content {
  padding-top: 0;
}
.topbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40px;
  background: linear-gradient(90.04deg, #25D393 0%, #A3FAD1 100%);
}
.topbar .close {
  position: absolute;
  right: 0;
  margin-right: 10px;
  cursor: pointer;
}
.topbar .content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.topbar .content .sale {
  font-size: 14px;
  line-height: 22px;
  color: #141414;
  margin-right: 30px;
}
.topbar .content .sold {
  font-size: 12px;
  line-height: 20px;
  color: #303030;
  margin-left: 10px;
}
.topbar .content .sold_value .ant-progress-outer {
  display: flex;
}
.ant-page-header {
  padding: 0;
  height: 56px;
}
.ant-page-header-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  background-color: #1D1D1D;
}
.ant-page-header-content a.logo { 
  display: flex;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding-left: 25px;
  height: 100%;
}
.ant-page-header-content a.logo img {
  width: 28px;
  height: 28px;
} 
.ant-page-header-content a span.title {
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-left: 8px;
}
.ant-page-header-content .account_info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 25px;
  height: 100%;
}
.account_info button {
  background: #06BE7F;
  border-radius: 4px;
  color: #FFFFFF;
  text-transform: capitalize;
}
.account_info button:hover {
  background: #05a76e;
  color: #FFFFFF;
}
.App .citizen-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(20, 20, 20);
  padding-top: 10px;
}
.citizen-menu li.ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0) !important;
  border-bottom: 2px solid #06BE7F;
}
.citizen-menu li.ant-menu-item {
  margin-bottom: 0 !important;
}
.citizen-menu li.ant-menu-item a {
  font-family: RobotoMono;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #DBDBDB;
}
.citizen-menu li.ant-menu-item-selected a {
  color: #06BE7F;
}
.main, .exchange {
  display: flex;
  flex-direction: column;
}
.main .citizen {
  max-width: 505px;
  width: 100%;
}
.exchange .citizen {
  max-width: 1185px;
  width: 100%;
}
.citizen {
  display: flex;
  flex-direction: column;
  align-items: center;  
  margin: auto;
  margin-top: 0;
  margin-bottom: 20px;
}
.citizen .title {
  font-size: 30px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #DBDBDB;
  margin-top: 24px;
  margin-bottom: 24px;
}
.citizen .connect_wallet {
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7D7D7D;
  margin-bottom: 24px;
}
.exchange .citizen .connect_wallet {
  max-width: 739px;
}
.cards {
  display: flex;
  flex-direction: revert;
  justify-content: space-between;
  width: 100%;
}
.citizen .sold_amount {
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #DBDBDB;
}
.citizen .sold_value {
  margin-bottom: 5px;
}
.citizen .token_amount {
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #DBDBDB;
  margin-bottom: 24px;
}
.citizen .mint_nft {
  border: 1px solid #434343;
  border-radius: 4px;
  max-width: 363px;
  width: 100%;
  margin: auto;
  overflow: hidden;
}
.citizen .mint_nft img {
  max-height: 300px;
}
.citizen .mint_nft .nft_info {
  padding: 24px;
  display: flex;
  align-items: start;
  flex-direction: column;
}
.citizen .mint_nft .nft_info .information {
  display: flex;
  flex-direction: column;
}
.citizen .mint_nft .nft_info span {
  text-align: left;
}
.citizen .mint_nft .nft_info .nft_title {
  font-size: 16px;
  line-height: 24px;
  color: #DBDBDB;
}
.citizen .mint_nft .nft_info .nft_price,
.citizen .mint_nft .nft_info .nft_supply,
.citizen .mint_nft .nft_info .nft_special {
  color: #ACACAC;
}
.ant-btn.btn_mint {
  max-width: 363px;
  width: 100%;
  height: 40px;
  margin-top: 24px;
  background: #06BE7F;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
}
.btn_link {
  max-width: 363px;
  width: 150px;
  height: 40px;
  background: #06BE7F;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
}
.links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 363px;
  width: 100%;
  margin: auto;
  margin-bottom: 50px;
}